import React from "react";

import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import FeaturesHome from "../partials/Features";
import FeaturesBlocks from "../partials/FeaturesBlocks";
import Testimonials from "../partials/Testimonials";
import Footer from "../partials/Footer";
import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";

function Home() {
  return (
    <Layout>
      <SEO siteTitle="Error 404 - Not Found" />
      <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
        <div className="flex flex-col min-h-screen overflow-hidden">
          <Header />
          {/*  Page content */}
          <main className="flex-grow">
            <section className="relative">
              <div className="max-w-6xl mx-auto px-4 sm:px-6">
                {/* Hero content */}
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                  {/* Section header */}
                  <div className="text-center pb-12 md:pb-16">
                    <h1
                      className="text-3xl sm:text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
                      data-aos="zoom-y-out"
                    >
                      Error 404
                    </h1>
                    <div className="max-w-3xl mx-auto">
                      <p
                        className="text-xl text-gray-600 mb-8"
                        data-aos="zoom-y-out"
                        data-aos-delay="150"
                      >
                        Nothing Found
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
